import React from "react";
import styles from "./UserDetail.module.scss";
import GenButton from "./GenButton";
import {ReactComponent as Closer} from './../assets/close.svg';
import {DataService} from './../store/DataService';
import { Date24 } from "../AppConstants";
import AlertBox from "./AlertBox";


const UserDetail = (props) => {
    const [locvalue,setLocValue] = React.useState(0);
    const [rolevalue,setRoleValue] = React.useState(0);
    const [showallprac,setShowAllPrac] = React.useState(false);
    const dataservice = new DataService({guid:props.globals.sessionguid});
    const lnref = React.useRef();
    const fnref = React.useRef();
    const miref = React.useRef();
    const emref = React.useRef();
    const unref = React.useRef();
    const acrefy = React.useRef();
    const acrefn = React.useRef();
    const pwref = React.useRef();
    const pwcref = React.useRef();
    const fprefy = React.useRef();
    const fprefn = React.useRef();
    const qidref = React.useRef();

    let newinputs = {};
    let alls = props.userdata.allservices || [];
    alls.forEach((s)=> {
        newinputs["svcopt_"+s.stid] = false;
    })
    alls = props.user.msgtypeskills || [];
    alls.forEach((svc)=> {
        newinputs["svcopt_"+svc] = true;
    })
    alls = props.userdata.allsystems || [];
    alls.forEach((s) => {
        newinputs["sysopt_"+s.stid] = false;
    })
    alls = props.user.systemskills || [];
    alls.forEach((sys) => {
        newinputs["sysopt_"+sys.systemid] = true;
    })
    alls = props.user.practiceskills || [];
    alls.forEach((p)=> {
        newinputs["upopt_"+p.pracid] = true;
    })
    const [inputs,setInputs] = React.useState(newinputs);

    const [selprac,setSelPrac] = React.useState(0);
    const [selsys,setSelSys] = React.useState(0);
    alls = props.user.practiceskills || [];
    const [authorized,setAuthorized] = React.useState(alls);
    const [selassigned,setSelAssigned] = React.useState([]);

    const onClose = (e) => {
        props.onClose(null);
    }


    const DoSave = (id) => {
        let active = acrefy.current.checked ? 'Y' : 'N';
        let fpw = fprefy.current.checked;
        let expdate = "1/1/2100 00:00:00";
        if (fpw) {
            expdate = "NULL";
        }
        let pwd = props.user.Password;
        let newpw = pwref.current.value;
        let confpw = pwcref.current.value;
        if (newpw.length > 0 && newpw.length < 6) {
            AlertBox("Password MUST be at least 6 characters.");
            return;
        }
        if (newpw.length) {
            if (newpw !== confpw) {
                AlertBox("Password and Confirm Password MUST match.");
                return;
            }
            pwd = newpw;
        }
        let roleid = rolevalue;
        if (roleid < 1) {
            AlertBox("User MUST have a Role selected. Please select a Role.");
            return;
        }
        let roledesc = "";
        props.userdata.roles.forEach(obj=> {
            if (obj.RoleID===roleid) {
                roledesc = obj.Description;
            }
        });
        let locid = locvalue;
        let locdesc="";
        props.userdata.locations.forEach(obj=>{
            if (obj.LocationID===locid) {
                locdesc = obj.Name;
            }
        })

        const lname = lnref.current.value;
        const fname = fnref.current.value;
        const middle = miref.current.value;
        const uname = unref.current.value;

        if (uname.length < 6) {
            AlertBox("Username MUST be at least 6 characters.");
            return;
        }

        if (props.user.UserAccountID===0) {
            if (!newpw.length) {
                AlertBox("New User MUST be given a Password.");
                return;
            }
            if (lname.length < 1 && fname.length < 1) {
                AlertBox("New User MUST have First and Last names.");
                return;
            }
            if (uname.length < 1) {
                AlertBox("New User MUST have a User ID.");
                return;
            }
        }

        let svcs = [];
        let sysskills = [];
        let pracskills = [];
        for (let p in inputs) {
            let sa = p.split("_");
            let nm = sa[0];
            let value = sa[1];
            if (inputs[p]===true) {
                if (nm==='svcopt') {
                    svcs.push(value);
                }
                if (nm==='sysopt') {
                    sysskills.push({systemid:value,practiceid:0})
                }
                if (nm==='upopt') {
                    pracskills.push({pracid:value,mtid:0});
                }
            } 
        }

        let obj = {
            UserAccountID:props.user.UserAccountID,
            PersonID:props.user.PersonID,
            RoleID:roleid,
            LastName:lname,
            FirstName:fname,
            MiddleInitial:middle,
            Username:uname,
            Password:pwd,
            RestrictedAccess:"N",
            PasswordExpiration:expdate,
            DefaultLocationId:locid,
            PracticeId:props.globals.practiceid,
            Email:emref.current.value,
            queuetype:1,
            queueid:qidref.current.value,
            Location:locdesc,
            RoleTypeId:0,
            RoleDescription:roledesc,
            ActiveFlag:active,
            msgtypeskills:svcs,
            practiceskills:pracskills,
            systemskills:sysskills
        }
        dataservice.updateuser(props.globals.practiceid,obj).then((data) => {
            if (data.ERROR) {
                alert(data.ERROR);
                return;
            }
            obj.UserAccountID = ''+data.UAID || '0';
            props.onClose(obj);
        });
    }

    const Buttons = () => {

        return (
            <React.Fragment>
            <div style={{display:"block"}}>
                <GenButton 
                onclick={DoSave} 
                id={props.user.UserAccountID} 
                buttontext="Save Changes"
                bgcolor="white"
                color="black"
                width="100"
                height="30"
                />
                <div style={{marginTop:"30px"}}>
                <GenButton 
                    onclick={onClose} 
                    id={props.user.UserAccountID} 
                    buttontext="Cancel Changes"
                    bgcolor="white"
                    color="black"
                    width="100"
                    height="30"
                />
                </div>
            </div>
            </React.Fragment>
        )
    }

    React.useEffect(() => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.validatesession();
    },[props.globals.sessionguid])


    React.useEffect(()=> {
        props.userdata.locations.forEach((loc) => {
            if (loc.Name===props.user.Location) {
                setLocValue(loc.LocationID);
            }
        });
        setRoleValue(props.user.RoleID);
    },[props.userdata.locations,props.user.Location,props.user.RoleID])

    const onLocChange = (e) => {
        setLocValue(e.target.value);
    }
    const onRoleChange = (e) => {
        setRoleValue(e.target.value);
    }

    const ShowLocations = () => {
        
        let locs = [{LocationID:0,Name:""}];
        props.userdata.locations.forEach((loc) => {
            locs.push({LocationID:loc.LocationID,Name:loc.Name});
        });
        return (
            <select name="selloc" id="selloc" style={{width:"260px"}} value={locvalue} onChange={onLocChange}>
            {locs.map((loc,i)=> {
                return <option key={"locopt_"+i} value={loc.LocationID}>{loc.Name}</option>
            })}
            </select>
        )
    }

    const ShowRoles = () => {
        let rdonly = parseInt(props.user.UserAccountID || '0') === props.globals.useraccountid;

        let roles = [{RoleID:0,Description:""}];
        props.userdata.roles.forEach((role)=> {
            if (role.RoleID < 3 && props.globals.roleid < 3) {
                roles.push({RoleID:role.RoleID,Description:role.Description});
            }
            if (role.RoleTypeId > 2) {
                roles.push({RoleID:role.RoleID,Description:role.Description});
            }
        });
        return (
            <select name="selrole" id="selrole" style={{width:"170px"}} disabled={rdonly} value={rolevalue} onChange={onRoleChange}>
                {roles.map((role,i)=> {
                    return <option key={"roleopt_"+i} value={role.RoleID}>{role.Description}</option>
                })}
            </select>
        )
    }

    const checkAllSystems = (tf) => {
        const allprac = props.userdata.practices || [];
        let newobj = JSON.parse(JSON.stringify(inputs));
        allprac.forEach((prac)=> {
            const hassys = /\(([^)]+)\)/.test(prac.extended);
            if (hassys) {
                newobj["upopt_"+prac.pracid] = tf;
            }
        })
        setInputs(values => ({...values,...newobj}));
    }

    const checkAllPrac = (tf) => {
        const allprac = props.userdata.practices || [];
        let newobj = JSON.parse(JSON.stringify(inputs));
        allprac.forEach((prac)=> {
            newobj["upopt_"+prac.pracid] = tf;
        })
        setInputs(values => ({...values,...newobj}));
    }

    const handleCheckboxChange = (e) => {
        let name = e.target.name;
        let value = e.target.checked;
        let newobj = JSON.parse(JSON.stringify(inputs));
        setInputs(values => ({...values,[name]:value}));
        newobj[name] = value;
        //setDirtyFlag(!compareObj(newobj,originputs));        
    }

    const buildSkillSel = () => {
        const users = props.userdata.users || [];
        const user = users[parseInt(props.user.UserAccountID)] || {};
        let usermt = user.msgtypeskills || [];
        let allmt = props.userdata.allmsgtypes || [];
        let foundmsgtypes = [];
        let pmt = [];
        usermt.forEach((p)=> {
            pmt.push(''+p.mtid)
        })
        allmt.forEach((mt)=> {
            if (allmt.includes(mt.mtid)) {
                if (inputs["svcopt_"+mt.mtid]===true) {
                    foundmsgtypes.push({mtid:mt.mtid,Name:mt.Name});
                }
            }
        })
        return foundmsgtypes;
    }

    const ShowSkills = () => {
        let allmt = props.userdata.allmsgtypes || [];
        const mthalf = Math.ceil(allmt.length /2);
        const mtcol1 = allmt.slice(0,mthalf);
        const mtcol2 = allmt.slice(mthalf);

        let allprac = props.userdata.practices || [];
        let half = [];
        let column1 = [];
        let column2 = [];
        if (showallprac) {
            half = Math.ceil(allprac.length / 2);
            column1 = allprac.slice(0, half);
            column2 = allprac.slice(half);
        } else {
            allprac.forEach((prac)=> {
                const hassys = /\(([^)]+)\)/.test(prac.extended);
                if (hassys) {
                    column1.push(prac);
                }
            })

        }

        return <React.Fragment>
            <div className={styles.sysskillshdr} style={{borderBottom:"1px solid black"}}>Message Type Skills:</div>
            <div className={styles.svcskillsrow}>
                <div>
                {mtcol1.map((mt,i)=> {
                    let chk = false;
                    const mtid = mt.mtid;
                    const nm = "svcopt_"+mtid;
                    return <div key={'mt1_'+i}><label style={{userSelect:"none"}}><input type="checkbox" name={nm} checked={inputs[nm]} onChange={handleCheckboxChange} />{mt.Name}</label></div>
                })}
                </div>
                <div>
                {mtcol2.map((mt,i)=> {
                    let chk = false;
                    const mtid = mt.mtid;
                    const nm = "svcopt_"+mtid;
                    return <div key={'mt2_'+i}><label style={{userSelect:"none"}}><input type="checkbox" name={nm} checked={inputs[nm]} onChange={handleCheckboxChange} />{mt.Name}</label></div>
                })}
                </div>
            </div>
            <div className={styles.sysskillshdr} style={{borderBottom:"1px solid black"}}>
                <div>Practice Skills:</div>
                <div style={{marginLeft:"30px"}}>Select Practices agent has skill in</div>
                <div style={{marginLeft:"30px"}}><label><input type="checkbox" checked={showallprac} onChange={(e)=>setShowAllPrac(e.target.checked)} />Show All Practices</label></div>
            </div>
                <div style={{display:'flex'}}>
                    <div style={{flex:1}}>
                        {column1.map((prac,i) => {
                            const textParts = prac.extended.split(/\(([^)]+)\)/);
                            return <div><label key={"allprac1_"+i} style={{userSelect:"none"}}>
                            <input type="checkbox" name={"upopt_"+prac.pracid} checked={inputs["upopt_"+prac.pracid]} onChange={handleCheckboxChange} />
                            {textParts.map((part, index) => {
                                if (index % 2 === 1) {
                                    return <span key={index} style={{ color: 'green' }}>({part})</span>;
                                }
                                return <span key={index}>{part}</span>;
                            })}
                            </label></div>
                        })}
                    </div>
                    <div style={{flex:1}}>
                        {column2.map((prac,i) => {
                            const textParts = prac.extended.split(/\(([^)]+)\)/);
                            return <div><label key={"allprac2_"+i} style={{userSelect:"none"}}>
                            <input type="checkbox" name={"upopt_"+prac.pracid} checked={inputs["upopt_"+prac.pracid]} onChange={handleCheckboxChange} />
                            {textParts.map((part, index) => {
                                if (index % 2 === 1) {
                                    return <span key={index} style={{ color: 'green' }}>({part})</span>;
                                }
                                return <span key={index}>{part}</span>;
                            })}
                            </label></div>
                        })}                    
                    </div>
                    {/*
                    <div style={{flex:1}}>
                        {column3.map((prac,i) => {
                            return <div><label key={"allprac_"+i} style={{userSelect:"none"}}>
                            <input type="checkbox" name={"upopt_"+prac.pracid} checked={inputs["upopt_"+prac.pracid]} onChange={handleCheckboxChange} />{prac.extended}</label></div>
                        })}
                    </div>
                    */}

                </div>

            {/*
                <div style={{marginLeft:"50px"}}>


                {allprac.map((prac,i)=> {
                    return (
                        <div className={styles.pracskillsrow}>
                        <label key={"allprac_"+i} style={{userSelect:"none"}}>
                        <input type="checkbox" name={"upopt_"+prac.pracid} checked={inputs["upopt_"+prac.pracid]} onChange={handleCheckboxChange} />{prac.name}</label>
                        </div>
                    )
                })}
                </div>
            */}
            {/*<button style={{marginTop:"12px",marginLeft:"20px"}} onClick={(e)=>checkAllSystems(true)}>Check Systems</button>
            <button style={{marginLeft:"12px"}} onClick={(e)=>checkAllSystems(false)}>Uncheck Systems</button>*/}
            <button style={{marginTop:"12px",marginLeft:"20px"}} onClick={(e)=>checkAllPrac(false)}>Clear All</button>
        </React.Fragment>
    }


    const ShowSkillsSystems = () => {
        let usersys = props.user.systemskills || [];
        let allsys = props.userdata.allsystems || [];

        return <React.Fragment>
            <div className={styles.svcskillshdr}><div>Skills:</div></div>
                <div style={{marginLeft:"50px"}}>
                {allsys.map((sys,i)=> {
                    return (
                        <div className={styles.svcskillsrow}>
                        <label key={"allsys_"+i} style={{userSelect:"none"}}>
                        <input type="checkbox" name={"sysopt_"+sys.stid} checked={inputs["sysopt_"+sys.stid]} onChange={handleCheckboxChange} />{sys.stdesc}</label>
                        </div>
                    )
                })}
                </div>
        </React.Fragment>
    }

    const ShowSkillsOLD = () => {
        let allsvc = props.userdata.allservices || [];
        let allsys = props.userdata.allsystems || [];
        let allmt = props.userdata.allmsgtypes || [];
        if (allsvc.length < 1 && allsys.length < 1) return null;
        let usersvc = props.user.services || [];
        let pracs = props.userdata.practices || [];
        let pracmt = props.userdata.practicemsgtypes || [];
        let usersys = props.user.systemskills || [];


        const handleSelAssign = (event) => {
            const selectedValues = Array.from(event.target.selectedOptions, option => option.value);
            setSelAssigned(selectedValues);
        }

        const handleRemove = (event) => {
            //const selectedValues = Array.from(event.target.selectedOptions, option => option.value);
            let selagn = [];
            selassigned.forEach((s)=> {
                let sa = s.split('_');
                selagn.push({mtid:sa[0],pracid:sa[1]});
            })
            let agn = authorized.filter(item => !selagn.some(obj => obj.mtid===item.mtid && obj.pracid===item.pracid));
            setSelAssigned([]);
            setAuthorized(agn);
        }

        const handleAuth = (e) => {
            e.preventDefault();
            let asg = JSON.parse(JSON.stringify(authorized));
            let pid = ''+selprac;
            let sid = ''+selsys;
            let ok = true;
            asg.every((a)=> {
                if (''+a.mtid===sid && ''+a.pracid===pid) {
                    ok = false;
                    return false;
                }
                return true;
            })

            if (!ok) {
                AlertBox("Duplicate assignment.");
                return;
            }
            asg.push({mtid:sid,pracid:pid});
            setAuthorized(asg);
        }

        const buildText = (asg) => {
            let txt = "";
            let pname = props.userdata.indexedpractices[asg.pracid] || "?";
            pname = pname.slice(0,45);
            let sname = props.userdata.indexedmsgtypes[asg.mtid] || "?";
            txt = pname + " ("+sname+")";
            return txt;
        }

        const changePractice = (e) => {
            const pid = e.target.value;
            setSelPrac(pid);
            setSelSys(0);
        }

        const buildPracticeSel = () => {
            let foundpracs = [];
            let foundpids = [];
            pracmt.forEach((p)=> {
                if (inputs["svcopt_"+p.mtid]===true) {
                    if (!foundpids.includes(p.pracid)) {
                        foundpracs.push({pracid:p.pracid,name:props.userdata.indexedpractices[p.pracid]});
                        foundpids.push(p.pracid);
                    }
                }
            })
            foundpracs.sort((a,b)=>{
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            })
            return foundpracs;
        }

        const buildSkillSel = () => {
            let foundmsgtypes = [];
            let pmt = [];
            pracmt.forEach((p)=> {
                if (''+p.pracid===selprac) {
                    pmt.push(''+p.mtid)
                }
            })
            allmt.forEach((mt)=> {
                if (pmt.includes(mt.mtid)) {
                    if (inputs["svcopt_"+mt.mtid]===true) {
                        foundmsgtypes.push({mtid:mt.mtid,Name:mt.Name});
                    }
                }
            })
            return foundmsgtypes;
        }

        const selectedpractice = '' + selprac;
        const selectedsystem = '' + selsys;
        const remdis = selectedpractice === '0' || selectedsystem === '0';
        return <React.Fragment>
            <div className={styles.svcskillshdr}><div>Skills</div></div>
            <div className={styles.svcskillsrow}>
            {allmt.map((svc,i)=> {
                return (
                    <label key={"allsvc_"+i}>
                    <input type="checkbox" name={"svcopt_"+svc.mtid} checked={inputs["svcopt_"+svc.mtid]} onChange={handleCheckboxChange} />{svc.Name}</label>
                )
            })}
            </div>
            <div className={styles.skillstitle}>Authorized Accounts</div>
            <div className={styles.skillshdr}>
                <div>Practices</div>
                <div>Skill</div>
                <div>Actions</div>
                <div>Authorized</div>
            </div>
            <div className={styles.skillsrow}>
                <div>
                    <select name="selprac" style={{width:"340px"}} value={selprac} onChange={changePractice}>
                        <option value="0"></option>
                        {buildPracticeSel().map((p,i)=> {
                            return (<option key={"sp_"+i} value={p.pracid}>{p.name}</option>)
                        })}
                    </select>
                </div>
                <div>
                    <select name="selsys" style={{width:"100px"}} value={selsys} onChange={(e)=>setSelSys(e.target.value)}>
                    <option value="0"></option>
                        {buildSkillSel().map((sk,i) => {
                            return (<option key={"ss_"+i} value={sk.mtid}>{sk.Name}</option>)
                        })}
                    </select>
                </div>
                <div>
                    <div><button style={{width:"80px"}} onClick={handleAuth} disabled={remdis}>Authorize</button></div>
                    <div><button style={{marginTop:"10px",width:"80px"}} onClick={handleRemove}>Remove</button></div>
                </div>
                <div>
                    <select name="selassigned" multiple style={{height:"100px",width:"350px"}} onChange={handleSelAssign} value={selassigned}>
                        {authorized.map((obj,i) => {
                            let aname = buildText(obj);
                            let newobj = ""+obj.mtid+"_"+obj.pracid;
                            return (
                                <option key={"asg_"+i} value={newobj}>{aname}</option>
                            )
                        })}
                    </select>
                </div>
            </div>

        </React.Fragment>
    }

    let active = props.user.ActiveFlag === 'Y';
    let practiceid = '' + props.globals.practiceid;

    return (
        <div className={styles.detail}>
            <div className={styles.outer}>
                <div className={styles.inner}>
                    <div className={styles.detailheader}>
                        <div>User details ({props.user.UserAccountID})</div>
                        <div className={styles.headerright} onClick={onClose}><Closer /></div>
                    </div>
                    <div className={styles.lefthalf}>
                        <div className={styles.listrowhdr}>
                            <div>Last Name</div>
                            <div>First Name</div>
                            <div>Middle Initial</div>
                        </div>
                        <div className={styles.listrow}>
                            <div><input type="text" maxLength="30" name="lastname" ref={lnref} defaultValue={props.user.LastName} /></div>
                            <div><input type="text" maxLength="20" name="firstname" ref={fnref} defaultValue={props.user.FirstName} /></div>
                            <div><input type="text" maxLength="1" name="midinit" ref={miref} defaultValue={props.user.MiddleInitial} /></div>
                        </div>
                        <div className={styles.listrowhdr}>
                            <div>User ID</div>
                            <div>Email</div>
                            <div>Default Location</div>
                        </div>
                        <div className={styles.listrow}>
                            <div><input type="text" maxLength="20" name="userid" ref={unref} defaultValue={props.user.Username} /></div>
                            <div><input type="text" maxLength="30" name="email" autoComplete="new-password" ref={emref} defaultValue={props.user.Email} /></div>
                            <div>
                                <ShowLocations />
                            </div>
                        </div>
                        <div className={styles.listrowhdr}>
                            <div>Role</div>
                            <div>Password</div>
                            <div>Confirm Password</div>
                        </div>
                        <div className={styles.listrow}>
                            <div>
                                <ShowRoles />
                            </div>
                            <div><input type="password" maxLength="30" autoComplete="new-password" name="upwd" ref={pwref} /></div>
                            <div><input type="password" maxLength="30" autoComplete="new-password" name="confpwd" ref={pwcref} /></div>
                        </div>
                        <div className={styles.listrowhdr}>
                            <div style={{width:"190px",fontSize:"14px"}}>Active</div>
                            <div style={{width:"190px",fontSize:"14px"}}>Force Password Change</div>
                            {practiceid==='1' && <div style={{width:"190px",fontSize:"14px"}}>Phone Queue ID</div>}
                        </div>
                        <div className={styles.listrow}>
                            <div>
                                <label>
                                <input type="radio" name="active" id="activeyes" ref={acrefy} defaultChecked={active===true} />
                                Yes
                                </label>
                                <label style={{marginLeft:"10px"}}>
                                <input type="radio" name="active" id="activeno" ref={acrefn} defaultChecked={active===false} />
                                No
                                </label>
                            </div>
                            <div>
                                <label>
                                <input type="radio" name="forcepw" id="fpwyes" ref={fprefy} defaultChecked={false} />
                                Yes
                                </label>
                                <label style={{marginLeft:"10px"}}>
                                <input type="radio" name="forcepw" id="fpwno" ref={fprefn} defaultChecked={true} />
                                No
                                </label>
                            </div>
                            {practiceid==='1' && <div><input type="text" maxLength="30" name="queueid" ref={qidref} defaultValue={props.user.queueid} /></div>}
                        </div>
                        {practiceid==='1' && ShowSkills()}
                        <div className={styles.selecthdr} style={{display:"flex"}}>

                        </div>
                    </div>
                    <div className={styles.righthalf}>
                        <Buttons />
                    </div>
                </div>
            </div> 
        </div> 

    )
}

export default UserDetail;