import React from "react";
import styles from "./TicketDetail.module.scss";
import GenButton from "./GenButton";
import GenPrompt from "./GenPrompt";
import AddNote from "./AddNote";
import {ReactComponent as Closer} from './../assets/close.svg';
import {DataService} from './../store/DataService';
import { RELATIONSHIPS, APPTREASONS, ACTIONADDNOTE, ACTIONREASONS, formatPhoneNumber, Date12, STATEID_CLOSED, STATEID_QUEUE,
    MSGTYPE_APPTREQUEST,MSGTYPE_APPTCANCEL,MSGTYPE_APPTINQUIRY,MSGTYPE_APPTRESCHEDULE, PRIORITIES } from "./../AppConstants";
import GenConfirm from './GenConfirm';
import GenTertiary from "./GenTertiary";

const TicketDetail = (props) => {
    const tk = props.ticket || {};
    const msg = tk.msg || {};
    const [ticket,setTicket] = React.useState(tk);
    const [ticketpriority,setTicketPriority] = React.useState(tk.priority || 0);
    const [msgdetail,setMsgDetail] = React.useState(msg.detail || {});
    const [msginfo,setMsgInfo] = React.useState(msg.info || []);
    const [msgevents,setMsgEvents] = React.useState(msg.events || []);
    const [msgactions,setMsgActions] = React.useState(msg.actions || []);
    const [msghistory,setMsgHistory] = React.useState(msg.history || []);
    const [workflow,setWorkFlow] = React.useState(tk.workflow || []);
    const [shownote,setShowNote] = React.useState(false);
    const [showevents,setShowEvents] = React.useState(false);
    const [showisholding,setShowIsHolding] = React.useState(false);
    const [showclosed,setShowClosed] = React.useState(false);

    const toStateRef = React.useRef(0);
    const askholding = React.useRef(props.askholding || false);
    const allowpriorityedit = React.useRef(props.allowpriorityedit || false);
    const fromsearch = React.useRef(props.fromsearch || false);
    const disableworkflow = React.useRef(props.disableworkflow || false);
    const statuses = ['Closed','Open','2','3','4','5','6'];
    const fdate = msgdetail.CreateDate || "";
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
    const fd = new Date(fdate);
    const fmted = fd.toLocaleDateString(undefined,options);
    const timeopts = {hour:"numeric",minute:"numeric",hour12:true};
    const fmttime = fd.toLocaleTimeString("en-us",timeopts);
    let ptdob = new Date(msgdetail.PatientDOB).toLocaleDateString(undefined,options);
    if (ptdob==="Invalid Date" || ptdob.indexOf("1900") >= 0) ptdob = "";
    if (ptdob.length > 0) {
        ptdob += " (" + msgdetail.PatientAge+")";
    }
    const patient = msgdetail.PatientFirstName + " " + msgdetail.PatientLastName;
    const pfn = msgdetail.ProvFirstName || "";
    const midin = msgdetail.MiddleInitial || "";
    const pln = msgdetail.ProvLastName || "";
    const cr = msgdetail.CallerRelationship || "";

    let relationship = "";
    if (cr.length > 0) {
        relationship = RELATIONSHIPS[cr];
    }
    let provider = pfn + " ";
    if (midin.length > 0) {
        provider += midin + " "
    }
    provider += pln;
    const cfn = msgdetail.CallerFirstName || "";
    const cln = msgdetail.CallerLastName || "";
    const caller = cfn + " " + cln;
    const inf = msgdetail.info || [];
    const hasinfo = inf.length > 0;
    const pracname = msgdetail.PracName || "";
    const tmsg = "Ticket details "+ pracname;
    const isappt = [MSGTYPE_APPTCANCEL,MSGTYPE_APPTINQUIRY,MSGTYPE_APPTRESCHEDULE,MSGTYPE_APPTREQUEST].includes(msgdetail.MessageTypeID);
    const isrequest = msgdetail.MessageTypeID === MSGTYPE_APPTREQUEST;
    let newpt = 'N';
    if (isrequest) {
        newpt = msgdetail.NewPatient || 'N';
    }
    let andisabled = false;
    let sc = props.showClosed || false;
    if (sc) andisabled = true;
    

    let cbdisabled = false;
    let cbtitle = "";
    let isclosed = false;
    if (props.showClosed === true) {
        cbtitle = "Cannot close while Showing Closed Messages.";
        cbdisabled = true;
    }
    if (msgdetail.Ack==='N') {
        cbtitle = "Message has not been Acknowledged yet.";
        cbdisabled = true;
    }
    if (msgdetail.Status===0) {
        cbtitle = "Message already closed.";
        cbdisabled = true;
        if (props.globals.intext===1) isclosed = true;
    }
    const dummy = (ticketid,stateid,priority) => {}
    const dummy2 = (e) => {}

    const onClose = (e) => {
        const cb = props.doClose || dummy;
        cb(0,0,0);
    }

    const addNote = (e) => {
        setShowNote(true);
    }

    const cancelNote = () => {
        setShowNote(false);
    }

    const saveNote = (reason,cmt) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        cmt = cmt.replaceAll(/(?:\r\n|\r|\n)/g, '<br>');


        let obj = {
            msgid:msgdetail.MsgID,
            msgtype:msgdetail.MessageTypeID,
            respid:msgdetail.ResponsibleID,
            resptype:msgdetail.ResponsibleType,
            uacid:props.globals.useraccountid,
            actionid:ACTIONADDNOTE,
            cmt:cmt,
            reason:reason,
            ctmeth:"",
            ctpersonid:0,
            ctgroupid:0,
            auacid:props.globals.useraccountid,
            apracid:props.globals.userpracticeid,
            recname:""
        }
        dataservice.savenote(obj).then((data) => {
                if (data.ERROR) {
                    if (data.ERROR.length > 0) {
                        alert(data.ERROR);
                        return;
                    }
                }
                setShowNote(false);
                //onClose(0);
                let hist = {
                    CreateDate:new Date(),
                    Who:"Practice Admin",
                    ActionTaken: "Notes Added",
                    Details:cmt + " - Practice Admin",
                    EditRows:[]

                }
                let msghist = JSON.parse(JSON.stringify(msghistory));
                msghist.push(hist);
                setMsgHistory(msghist);
            })
        
    }

    const doAction = (ticketid,stateid,priority,holding=0) => {
        setShowIsHolding(false);
        const dataservice = new DataService({guid:props.globals.sessionguid});
        dataservice.gototicketstate(ticketid,stateid,priority,props.globals.useraccountid,holding).then((data)=> {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }

            if (data.hasOwnProperty('ACTION')) {
                let action = data.ACTION;
                if (action === 'inuse') {
                    alert("Ticket is already IN-PROGRESS.");
                    // Fake out the code below and call close routine to NOT display ticket detail
                    stateid = STATEID_QUEUE;
                    holding = 0;
                }
            }
            
            if (ticketid > 0 && holding > 0 && stateid === STATEID_QUEUE) {
                window.focus();
                navigator.clipboard.writeText(''+ticketid).then(() => {
                    alert('Ticket # '+ticketid+' was copied to clipboard. Please Paste it into Phone System.');
                })
                .catch((err) => {
                    console.error('Failed to copy text: ', err);
                    alert('Failed to copy text. Please manually Copy/Paste the ticket #'+ticketid);
                });
            }
            
            const cb = props.doClose || dummy;
            cb(ticketid,stateid,priority);
        })
    }

    const onActionYes = () => {
        const afnc = props.onAction || doAction;
        afnc(ticket.ticketid,toStateRef.current,ticketpriority,1);        
    }
    const onActionNo = () => {
        const afnc = props.onAction || doAction;
        afnc(ticket.ticketid,toStateRef.current,ticketpriority,0);
    }
    const onActionCancel = () => {
        setShowIsHolding(false);
    }

    const onAction = (stateid) => {
        console.log("ActionID="+stateid);
        toStateRef.current = stateid;
        if (askholding.current===true && ticket.holding===0 && ticket.washolding > 0 && stateid !== STATEID_CLOSED) {
            setShowIsHolding(true);
        } else {
            const afnc = props.onAction || doAction;
            afnc(ticket.ticketid,stateid,ticketpriority,ticket.holding);    
        }
    }

    const onActionHold = (e) => {
        toStateRef.current = STATEID_QUEUE;
        onActionYes();
    }

    const ShowWorkflow = () => {
        let hasip = false;
        let ipmsg = 'Note: Press "In-Progress" to take ownership of this ticket.';
        let isclosed = ticket.ticketname === 'CLOSED';
        if (fromsearch.current) {
            if (isclosed) return null;
            return (
                <React.Fragment>
                <div style={{marginTop:"10px",display:"flex",gap:"16px",alignItems:"center",height:"40px"}}>
                    <div style={{marginTop:"12px",fontSize:"18px",fontWeight:"bold",height:"30px",alignItems:"center"}}>Available Actions:</div>
                    <GenButton 
                        onclick={(e)=>{setShowClosed(true)}} 
                        id={ticket.ticketid} 
                        buttontext={"Close Ticket"}
                        bgcolor="white"
                        color="black"
                        width="120"
                        height="30"
                    />

                    {ticket.holding===0 && <GenButton
                        onclick={onActionHold} 
                        id={ticket.ticketid} 
                        buttontext={"Put On-Hold"}
                        bgcolor="white"
                        color="black"
                        width="120"
                        height="30"
                    />
                    }
                
                    </div>
                </React.Fragment>
                
            )
        }
        return (
            <React.Fragment>
                <div style={{marginTop:"10px",display:"flex",gap:"16px",alignItems:"center",height:"40px"}}>
                    <div style={{marginTop:"12px",fontSize:"18px",fontWeight:"bold",height:"30px",alignItems:"center"}}>Available Actions:</div>
                    {workflow.map((wf,i)=> {
                        let btxt = wf.buttontext;
                        if (btxt==="In-Progress") {
                            hasip = true;
                            if (ticket.holding > 0) {
                                btxt = 'Continue';
                                ipmsg = 'Note: Press "Continue" to take ownership of this ticket.';
                            }
                        }
                        return (
                            <div key={'wf_'+i}>
                                <GenButton 
                                    onclick={onAction} 
                                    id={wf.workflowstateid} 
                                    buttontext={btxt}
                                    bgcolor="white"
                                    color="black"
                                    width="120"
                                    height="30"
                                    />
                            </div>
                        )
                    })}
                    {hasip && <div style={{fontSize:"14px",fontStyle:"italic"}}>{ipmsg}</div>}
                </div>
            </React.Fragment>
        )
    }

    const handleState = (e) => {
        const val = e.target.value;
        setTicketPriority(val);
    }

    const ShowTicket = () => {
        let hasqueue = false;
        let holdmsg = ticket.holding===0 ? "" : " (HOLDING)";
        if (allowpriorityedit.current) {
            workflow.map((wf,i)=> {
                if (wf.buttontext=="In-Queue") {
                    hasqueue = true;
                }
            });    
        }
        const showPriorities = () => {
            return (
                <div><b>Priority:</b> 
                <select style={{marginLeft:"6px",height:"24px",fontSize:"16px"}} name="selpri" value={ticketpriority} onChange={handleState}>
                    {PRIORITIES.map((p,i)=> {
                        return <option value={i}>{p}</option>
                    })}
                </select>
                </div>
            )
        }
        return (
            <div style={{marginTop:"10px"}}>
                <div className={styles.tkrow}>
                    <div><b>Ticket #:</b> {ticket.ticketid}</div>
                    {!hasqueue && <div><b>Priority:</b> {PRIORITIES[ticketpriority]}</div>}
                    {hasqueue && showPriorities()}
                    <div><b>Status:</b> {ticket.ticketname} {holdmsg}</div>
                </div>
                <div className={styles.tkrow} style={{marginTop:"6px"}}>
                    <div><b>Message Type:</b> {ticket.msgname}</div>
                    <div><b>Created:</b> {Date12(ticket.createdate)}</div>
                    <div><b>Callback Phone:</b> {ticket.callbackphone}</div>
                </div>
                {!disableworkflow.current && <ShowWorkflow />}
            </div> 
        )
    }

    const ShowHistory = () => {
        
        if (!msghistory.length) {
            return (
                <React.Fragment>
                <div className={styles.partheader}>History</div>
                <div>No History for this message</div>
                </React.Fragment>
            )
        }
        return (
            <React.Fragment>
            <div className={styles.partheader}>History</div>
            {msghistory.map((obj,i) => {
                let det = obj.Details.replaceAll(/(?:\r\n)/g, '<br>');
                let edits = obj.EditRows || [];
                console.log("detail "+det);
                det = det.split('<br>');
                let msgdate = Date12(obj.CreateDate);
                return (
                    <div key={'hist_'+i}>
                        <div className={styles.histrowhdr}>
                            <div>Date</div><div>Event</div><div>Who</div><div>Details</div>
                        </div>
                        <div className={styles.histrow}>
                            <div>{msgdate}</div><div>{obj.ActionTaken}</div><div>{obj.Recipient}</div><div className={styles.details}>
                                {det.map((ln,i)=> {
                                    if (!ln.length) ln = "\n";
                                    return <div key={"ln_"+i} className={styles.newline}>{ln}</div>
                                })}
                                {edits.map((ln,i)=> {
                                    if (!ln.length) ln = "\n";
                                    return <div key={"lned_"+i} className={styles.newline}>{ln}</div>
                                })}
                            </div>
                        </div>
                    </div>
                )
            })}
            </React.Fragment>
        )
    }

    const ShowMessage = () => {
        const mbody = msgdetail.MessageBody || "";
        const details = mbody.split("\n");
        const setext = showevents ? "Hide Events" : "Show Events";
        return (
            <React.Fragment>
            <hr/>
            <div className={styles.detailheader}>
                <div>Message Details<span style={{marginLeft:"14px",fontSize:"18px"}}>(# {msgdetail.MsgID})</span></div>
            </div>
            {shownote && <AddNote globals={props.globals} methods={props.methods} onSave={saveNote} onCancel={cancelNote} showreason={false} />}
        
            <div className={styles.lefthalf}>
                <div className={styles.listrowhdr}>
                    <div>Msg Date</div><div>Msg Time</div><div>Status</div>
                </div>
                <div className={styles.listrow}>
                    <div>{fmted}</div><div>{fmttime}</div><div>{statuses[msgdetail.Status]}</div>
                </div>
                <div className={styles.listrowhdr}>
                    <div>Patient</div><div>Date of Birth</div><div>Provider</div>
                </div>
                <div className={styles.listrow}>
                    <div>{patient}</div><div>{ptdob}</div><div>{provider}</div>
                </div>
                <div className={styles.listrowhdr}>
                <div>Msg Type</div><div>Caller</div><div>Relationship</div>
                </div>
                <div className={styles.listrow}>
                <div>{msgdetail.Name}</div><div>{caller}</div><div>{relationship}</div>
                </div>
                <div className={styles.listrowhdr}>
                <div>Callback Phone</div>
                {isrequest && <div>New Patient</div>}
                {!isrequest && <div></div>}
                <div></div>
                </div>
                <div className={styles.listrow}>
                <div>{formatPhoneNumber(msgdetail.CallbackPhone)}</div>
                {isrequest && <div>{newpt}</div>}
                {!isrequest && <div></div>}
                <div></div>
                </div>
                {isappt && <ShowAppt />}
                {hasinfo && <ShowInfo />}
                
            </div>
            <div className={styles.righthalf}>
                <div><b>Message</b><br/>
                    {details.map((det,i)=> {
                        return <div key={"detail_"+i}>{det}</div>
                    })}
                </div>
                <div className={styles.actionheader2}>

                    <div style={{width:"30px"}}>&nbsp;</div>
                </div>
            </div>
            <ShowHistory />
            <div style={{marginTop:"10px",display:"flex"}}>
            <div>
                <GenButton 
                onclick={onClose} 
                id={msgdetail.MsgID} 
                buttontext="Close Detail"
                bgcolor="white"
                color="black"
                width="100"
                height="30"
                />
            </div>
            <div style={{marginLeft:"20px"}}>
                <GenButton globals={props.globals} methods={props.methods}
                    buttontext="Add Note"
                    id="0"
                    preicon="plus"
                    iconcolor="black"
                    bgcolor="white"
                    color="black"
                    width="100"
                    height="30"
                    onclick={addNote}
                    disabled={andisabled}
                    />
            </div>
            <div style={{marginLeft:"20px"}}>
                <GenButton globals={props.globals} methods={props.methods}
                    buttontext={setext}
                    id="0"
                    bgcolor="white"
                    color="black"
                    width="100"
                    height="30"
                    onclick={()=>setShowEvents(!showevents)}
                    disabled={false}
                    />
            </div>
        </div>
        </React.Fragment>
        )
    }

    const ShowAppt = () => {
        //const reason = APPTREASONS[msgdetail.Reason || "5"];
        const reason = msgdetail.ApptDesc || "";
        const doptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour:'numeric',minute:'numeric',hours12:true};
        //const doptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'};
        let apptdate = "";
        const ad = msgdetail.ApptDate || "";
        if (ad.length > 0) {
            const dt = new Date(ad);
            apptdate = dt.toLocaleDateString(undefined,doptions);
            if (apptdate.indexOf("Mon, Jan 1, 1900") === 0) {
                if (apptdate.indexOf(", 00:00") > 0 || apptdate.indexOf(", 12:00 AM") > 0) {
                    apptdate = "";
                } else {
                    apptdate = apptdate.replace("Mon, Jan 1, 1900, ","");
                }
                
            }
        }
        const apptloc = msgdetail.LocationName || "";
 
        return (
            <React.Fragment>
            <div className={styles.partheader}>Appointment Data</div>
            <div className={styles.listrowhdr}>
                <div>Date</div><div>Location</div><div>Reason</div>
            </div>
            <div className={styles.listrow}>
                <div>{apptdate}</div><div>{apptloc}</div><div>{reason}</div>
            </div>
            </React.Fragment>
        )
    }

    const ShowInfo = () => {
        return (
            <React.Fragment>
            <div className={styles.partheader}>Extra Information</div>
            <div className={styles.listrowhdr}>
                {msginfo.map((obj,i) => {
                    return <div key={'desc_'+i}>{obj.Description}</div>
                })}
            </div>
            <div className={styles.listrow}>
                {msginfo.map((obj,i) => {
                    return <div key={'val_'+i}>{obj.Val}</div>
                })}
            </div>
            </React.Fragment>
        )
    }

    const ShowEvents = () => {
        const events = ticket.events || [];

        return (
            <React.Fragment>
            <div className={styles.partheader}>Ticket Events (Workflow)</div>
            <div className={styles.evrowhdr}>
                <div>Datetime</div>
                <div>State</div>
                <div>Priority</div>
                <div>Username</div>
            </div>
            {events.map((ev,i)=> {
                const evid = ev.ticketeventid;
                const evdate = Date12(ev.eventdate || "");
                const evstatus = ev.statusname || "CREATE";
                const evpri = ev.priname || "";
                const evuser = ev.username || "";
                return (
                    <div key={i} className={styles.evrow}>
                        <div>{evdate}</div>
                        <div>{evstatus}</div>
                        <div>{evpri}</div>
                        <div>{evuser}</div>
                    </div>
                )
            })}
            </React.Fragment>
        )
    }

    const saveCloseReason = (cmt) => {
        const dataservice = new DataService({guid:props.globals.sessionguid});
        //cmt = cmt.replaceAll(/(?:\r\n|\r|\n)/g, '<br>');

        cmt = cmt.replaceAll(/(?:\r\n|\r|\n)/g, '<br>');


        let obj = {
            msgid:msgdetail.MsgID,
            msgtype:msgdetail.MessageTypeID,
            respid:msgdetail.ResponsibleID,
            resptype:msgdetail.ResponsibleType,
            uacid:props.globals.useraccountid,
            actionid:ACTIONADDNOTE,
            cmt:cmt,
            reason:'Ticket Close',
            ctmeth:"",
            ctpersonid:0,
            ctgroupid:0,
            auacid:props.globals.useraccountid,
            apracid:props.globals.userpracticeid,
            recname:""
        }
        dataservice.savenote(obj).then((data) => {
            if (data.ERROR) {
                if (data.ERROR.length > 0) {
                    alert(data.ERROR);
                    return;
                }
            }
            const cb = props.closeticket || dummy2;
            cb(0);
            onClose(0);
        })
        
    }

    const doCloseCancel = () => {
        setShowClosed(false);
    }

    const doCloseOK = (cmt) => {
        setShowClosed(false);
        saveCloseReason(cmt);
    }

    return (
        <React.Fragment>
            <div className={styles.detail}>
                <div className={styles.outer}>
                    <div className={styles.inner}>
                        <div className={styles.detailheader}>
                            <div>{tmsg}</div>
                            <div className={styles.headerright} onClick={onClose}><Closer /></div>
                        </div>
                        <ShowTicket />
                        <ShowMessage />
                        {showevents && <ShowEvents />}
                        {showclosed && <GenPrompt 
                            text={"Reason for Closing #" + ticket.ticketid + ":"} 
                            onConfirm={doCloseOK} 
                            onCancel={doCloseCancel} 
                            inpvalue={""} 
                            width={300} 
                            />}

                        {showisholding && <GenTertiary 
                            globals={props.globals} 
                            methods={props.methods}
                            width="580"
                            text="Is caller still Holding on-line?"
                            onCancel = {onActionCancel}
                            onYes = {onActionYes}
                            onNo = {onActionNo}
                            />}
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default TicketDetail;